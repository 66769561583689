@font-face {
  font-family: 'icons';
  src:  url('assets/fonts/icons.eot?9c1zn6');
  src:  url('assets/fonts/icons.eot?9c1zn6#iefix') format('embedded-opentype'),
    url('assets/fonts/icons.ttf?9c1zn6') format('truetype'),
    url('assets/fonts/icons.woff?9c1zn6') format('woff'),
    url('assets/fonts/icons.svg?9c1zn6#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-business:before {
  content: "\e900";
}
.icon-academy:before {
  content: "\e957";
}
.icon-decision-management-plans:before {
  content: "\e958";
}
.icon-decisional-schemes:before {
  content: "\e959";
}
.icon-decisional-table:before {
  content: "\e95a";
}
.icon-organizations:before {
  content: "\e95b";
}
.icon-tasks:before {
  content: "\e95c";
}
.icon-flag-en .path1:before {
  content: "\e948";
  color: rgb(255, 255, 255);
}
.icon-flag-en .path2:before {
  content: "\e949";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-flag-en .path3:before {
  content: "\e94a";
  margin-left: -1.5em;
  color: rgb(0, 82, 180);
}
.icon-flag-en .path4:before {
  content: "\e94b";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-flag-en .path5:before {
  content: "\e94c";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-flag-en .path6:before {
  content: "\e94d";
  margin-left: -1.5em;
  color: rgb(0, 82, 180);
}
.icon-flag-en .path7:before {
  content: "\e94e";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-flag-en .path8:before {
  content: "\e94f";
  margin-left: -1.5em;
  color: rgb(0, 82, 180);
}
.icon-flag-en .path9:before {
  content: "\e950";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-flag-en .path10:before {
  content: "\e951";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-flag-en .path11:before {
  content: "\e952";
  margin-left: -1.5em;
  color: rgb(0, 82, 180);
}
.icon-flag-en .path12:before {
  content: "\e953";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-flag-it .path1:before {
  content: "\e954";
  color: rgb(255, 255, 255);
}
.icon-flag-it .path2:before {
  content: "\e955";
  margin-left: -1.5em;
  color: rgb(109, 165, 68);
}
.icon-flag-it .path3:before {
  content: "\e956";
  margin-left: -1.5em;
  color: rgb(216, 0, 39);
}
.icon-show:before {
  content: "\e901";
}
.icon-Add:before {
  content: "\e902";
}
.icon-Alert:before {
  content: "\e903";
}
.icon-Arrow-rounded:before {
  content: "\e904";
}
.icon-attachment:before {
  content: "\e905";
}
.icon-Avatar:before {
  content: "\e906";
}
.icon-Backward-arrow-small:before {
  content: "\e907";
}
.icon-Backward-arrow:before {
  content: "\e908";
}
.icon-big_add:before {
  content: "\e909";
}
.icon-brain:before {
  content: "\e90a";
}
.icon-cestino:before {
  content: "\e90b";
}
.icon-checkmark:before {
  content: "\e90c";
}
.icon-circle_info:before {
  content: "\e90d";
}
.icon-circle_Warning:before {
  content: "\e90e";
}
.icon-Clock:before {
  content: "\e90f";
}
.icon-Close:before {
  content: "\e910";
}
.icon-Comment:before {
  content: "\e911";
}
.icon-createdecisionalscheme:before {
  content: "\e912";
}
.icon-decision_one:before {
  content: "\e913";
}
.icon-decisiontablestart:before {
  content: "\e914";
}
.icon-Down-arrow-small-thin:before {
  content: "\e915";
}
.icon-Down-arrow-small:before {
  content: "\e916";
}
.icon-Down-arrow:before {
  content: "\e917";
}
.icon-edit:before {
  content: "\e918";
}
.icon-education:before {
  content: "\e919";
}
.icon-Facebook:before {
  content: "\e91a";
}
.icon-Flag:before {
  content: "\e91b";
}
.icon-folders:before {
  content: "\e91c";
}
.icon-Forward-arrow-small:before {
  content: "\e91d";
}
.icon-Forward-arrow:before {
  content: "\e91e";
}
.icon-gird-nine:before {
  content: "\e91f";
}
.icon-grid-four-2:before {
  content: "\e920";
}
.icon-grid-interface-four:before {
  content: "\e921";
}
.icon-grid-interface-line:before {
  content: "\e922";
}
.icon-group:before {
  content: "\e923";
}
.icon-Heart:before {
  content: "\e924";
}
.icon-idea:before {
  content: "\e925";
}
.icon-info:before {
  content: "\e926";
}
.icon-Instagram:before {
  content: "\e927";
}
.icon-layers:before {
  content: "\e928";
}
.icon-learnbutterfly:before {
  content: "\e929";
}
.icon-linkedin:before {
  content: "\e92a";
}
.icon-lock_open:before {
  content: "\e92b";
}
.icon-lock-open:before {
  content: "\e92c";
}
.icon-lock-open1:before {
  content: "\e92d";
}
.icon-lock:before {
  content: "\e92e";
}
.icon-lock1:before {
  content: "\e92f";
}
.icon-managedecisionplan:before {
  content: "\e930";
}
.icon-manageorganization:before {
  content: "\e931";
}
.icon-managetask:before {
  content: "\e932";
}
.icon-Menu:before {
  content: "\e933";
}
.icon-Options:before {
  content: "\e934";
}
.icon-upload:before {
  content: "\e935";
}
.icon-pdf:before {
  content: "\e936";
}
.icon-Profile:before {
  content: "\e937";
}
.icon-punto_esclamativo:before {
  content: "\e938";
}
.icon-Quote:before {
  content: "\e939";
}
.icon-re_edit:before {
  content: "\e93a";
}
.icon-Search:before {
  content: "\e93b";
}
.icon-settings:before {
  content: "\e93c";
}
.icon-slider:before {
  content: "\e93d";
}
.icon-star-empty:before {
  content: "\e93e";
}
.icon-star-full:before {
  content: "\e93f";
}
.icon-stats:before {
  content: "\e940";
}
.icon-surfdecisionalscheme:before {
  content: "\e941";
}
.icon-surfframeworks:before {
  content: "\e942";
}
.icon-Twitter:before {
  content: "\e943";
}
.icon-Up-arrow-small:before {
  content: "\e944";
}
.icon-Up-arrow:before {
  content: "\e945";
}
.icon-warning-triangle:before {
  content: "\e946";
}
.icon-Web:before {
  content: "\e947";
}
.icon-Yes:before {
  content: "\e95d";
}
